<template>
  <section class="container690">
    <iq-card class="p-3 d-flex justify-content-between align-items-center">
      <div class="title">
        <i class="ri-arrow-left-s-line cursor-pointer mr-1" style="font-size:24px" @click="$router.back(-1)"></i>Knowledge Test
      </div>
    </iq-card>
    <iq-card v-if="languageOrTest" class="iq-card ">
      <div class="cardContent">
        <h6>
          Select Language
        </h6>
        <el-row class="mt-4 p-2">
          <el-col :span="6" v-for="(item,index) of languageList" :key="index">
            <el-radio v-model="language_id" :label="item.language_id">{{item.language}} </el-radio>
          </el-col>
        </el-row>
      </div>
      <div class="btnNext">
        <el-button type="primary" @click="selectLanguageGetQuestionnaire">Next</el-button>
      </div>
    </iq-card>
    <section v-else>
      <iq-card v-if="isExam" class="iq-card">
        <div class="cardContent">
          <div v-for="(item, index) of issuesList" :key="index">
            <div v-if="tag==index">
              <div class="d-flex justify-content-between">
                <h6>
                  {{ index + 1 }}.{{ item.topic }} ( {{item.type==1?'Single choice':'Multiple choice'}} )
                </h6>
                <div class="mr-3" style="color: red;">
                  {{ second }}
                </div>
              </div>
              <el-radio-group class="mt-4 p-2" v-model="item.user_answer" v-if="item.type==1">
                <el-radio style="min-width:120px;" v-for="(v,i) of item.option" :key="i" :label="i+1">{{v}}</el-radio>
              </el-radio-group>
              <el-checkbox-group class="mt-4 p-2" v-if="item.type==2" v-model="item.user_answer">
                <el-checkbox style="min-width:120px" v-for="(v,i) of item.option" :key="i" :label="i+1">{{v}}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="btnNext">
          <el-button v-if="tag!=issuesList.length-1" type="primary" @click="nextQuestion">Next</el-button>
          <el-button v-else type="primary" @click="submitQuestionnaire">Submit</el-button>
        </div>
      </iq-card>
      <iq-card v-else class="iq-card">
        <div style="height: 400px;">
          <section class="exam_style" v-if="is_passed==2">
            <img src="../../assets/images/course/testFail.png" alt="">
            <span class="my-3">{{score}} / {{total_score}}</span>
            <span style="color:#EF5959">Unfortunately, you did not pass the knowledge test. We appreciate your
              participation. You can take the knowledge test again in a different training.</span>
          </section>
          <section class="exam_style" v-else>
            <img src="../../assets/images/course/testPass.png" alt="">
            <span class="my-3">{{score}}</span>
            <span>Congratulations, you passed!</span>
          </section>
        </div>
      </iq-card>
    </section>
  </section>
</template>
<script>
  export default {
    components: {
    },
    data() {
      return {
        language_id: '',
        second: 0,
        languageList: [],
        languageOrTest: true,
        issuesList: [],
        timer: 0,
        tag: 0,
        isExam: true,
        is_passed: '',
        score: '',
        total_score: '',
      };
    },
    created() {
      this.clickExam();
    },

    methods: {
      countDown() {
        this.second = this.timer;
        clearInterval(this.promiseTimer);
        this.promiseTimer = setInterval(() => {
          if (this.second == 0) {
            this.nextQuestion();
          } else {
            this.second -= 1;
          }
        }, 1000);
      },

      nextQuestion() {
        if (this.tag != this.issuesList.length - 1) {
          this.second = this.timer;
          this.tag += 1;
          this.countDown();
        } else {
          // 提交试卷
          clearInterval(this.promiseTimer);
          this.submitQuestionnaire()
        }
      },

      // 提交试卷
      submitQuestionnaire() {
        let issuesList = JSON.parse(JSON.stringify(this.issuesList))
        issuesList.forEach(v => {
          if (v.type == 1) v.user_answer = [v.user_answer]
        })
        let data = {
          training_id: this.$route.query.id,
          questionnaire_id: this.questionnaire_id,
          language_id: this.language_id,
          course_registration_id: this.$route.query.course_registration_id,
          topic: JSON.stringify(issuesList)
        }
        this.$http.submitQuestionnaire(data).then(res => {
          if (res.status == 200) {
            this.isExam = false
            this.is_passed = res.data.is_passed
            this.score = res.data.score
            this.total_score = res.data.total_score
          }
        })
      },

      clickExam() {
        this.$http.clickExam({ training_id: this.$route.query.id }).then(res => {
          if (res.status == 200) {
            this.languageList = res.data;
          } else {
            this.$router.replace(`/training_detail?id=${this.$route.query.id}`)
          }
        })
      },

      selectLanguageGetQuestionnaire() {
        if (this.language_id != '') {
          this.$http.selectLanguageGetQuestionnaire({
            training_id: this.$route.query.id,
            language_id: this.language_id,
          }).then(res => {
            if (res.status == 200) {
              this.timer = res.data.duration;
              this.questionnaire_id = res.data.questionnaire_id;
              this.issuesList = res.data.topic;
              this.issuesList.forEach(ele => {
                if (ele.type == 1) {
                  this.$set(ele, 'user_answer', '')
                } else {
                  this.$set(ele, 'user_answer', [])
                }
              });
              this.countDown();
              this.languageOrTest = false;
            }
          })
        } else {
          this.$message.warning("Please select the language first before proceeding to the next step!")
        }
      }

    },

    beforeDestroy() {
      clearInterval(this.promiseTimer);
    },

  };
</script>
<style lang="scss" scoped>
  ::v-deep .el-radio__label {
    white-space: pre-wrap;
    word-wrap: normal;
  }

  ::v-deep .el-checkbox__label {
    white-space: pre-wrap;
    word-wrap: normal;
  }

  ::v-deep .el-checkbox__inner {
    /* border-radius: 7px; */
  }

  .iq-card {
    padding: 22px 15px;
    font-size: 16px;

    .title {
      color: #333333;
    }

    h6 {
      font-size: 18px;
    }
  }

  .cardContent {
    min-height: 200px;
  }

  .btnNext {
    display: flex;
    justify-content: end;
  }

  .exam_style {
    display: flex;
    flex-direction: column;
    padding: 60px;
    align-items: center;
  }
</style>